import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Royals({ data }) {
  return (
    <Layout>
      <Helmet title="Royals | Lorde" />

      <h1>Royals</h1>

      <h2>Lorde</h2>

      <h4>Key: B</h4>

      <h4>Tempo: 90</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>B</Ch>I've never seen a diamond in the flesh
          </p>
          <p>I cut my teeth on wedding rings in the movies</p>
          <p>And I'm not proud of my address - in the torn up town</p>
          <p>No postcode envy</p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>B</Ch>But every song's like: gold teeth, grey goose, trippin' in
            the bathroom
          </p>
          <p>Blood stains, ballgowns, trashin' the hotel room</p>
          <p>
            <Ch>A</Ch>We don't care - we're driving Cadillacs in our <Ch>E</Ch>
            dreams
          </p>
        </PreChorus>
        <PreChorus>
          <p>
            <Ch>B</Ch>But every song's like: gold teeth, grey goose, trippin' in
            the bathroom
          </p>
          <p>Blood stains, ballgowns, trashin' the hotel room</p>
          <p>
            <Ch>A</Ch>We don't care - we're driving <Ch>E</Ch>Cadillacs in our
            dreams
          </p>
        </PreChorus>
        <PreChorus>
          <p>
            <Ch>B</Ch>But everybody's like Cristal, Mayback, diamonds on your
            timepiece
          </p>
          <p>Jet planes, islands, tigers on a gold leash</p>
          <p>
            <Ch>A</Ch>We don't care - we aren't <Ch>E</Ch>caught up in your love
            affair
          </p>
        </PreChorus>
        <Chorus>
          <p>
            And we'll never be <Ch>B</Ch>royals - It don't run in our blood
          </p>
          <p>
            That kind of <Ch>A</Ch>lux just ain't for us
          </p>
          <p>
            We crave a <Ch>E</Ch>different kind of buzz
          </p>
          <p>
            Let me be your <Ch>B</Ch>ruler - you can call me Queen Bee
          </p>
          <p>
            And baby I'll <Ch>A</Ch>rule, I'll rule, I'll rule, I'll rule
          </p>
          <p>
            <Ch>E</Ch>Let me live that fantasy<Ch>B</Ch>
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>B</Ch>My friends and I we've cracked the code
          </p>
          <p>We count our dollars on the train to the party</p>
          <p>And everyone who knows us knows that we're fine with this</p>
          <p>
            {" "}
            We didn't come from <Ch>E</Ch>money
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>B</Ch>But every song's like: gold teeth, grey goose, trippin' in
            the bathroom
          </p>
          <p>Blood stains, ballgowns, trashin' the hotel room</p>
          <p>
            <Ch>A</Ch>We don't care - we're driving Cadillacs in our <Ch>E</Ch>
            dreams
          </p>
        </PreChorus>
        <PreChorus>
          <p>
            <Ch>B</Ch>But every song's like: gold teeth, grey goose, trippin' in
            the bathroom
          </p>
          <p>Blood stains, ballgowns, trashin' the hotel room</p>
          <p>
            <Ch>A</Ch>We don't care - we're driving <Ch>E</Ch>Cadillacs in our
            dreams
          </p>
        </PreChorus>
        <PreChorus>
          <p>
            <Ch>B</Ch>But everybody's like Cristal, Mayback, diamonds on your
            timepiece
          </p>
          <p>Jet planes, islands, tigers on a gold leash</p>
          <p>
            <Ch>A</Ch>We don't care - we aren't <Ch>E</Ch>caught up in your love
            affair
          </p>
        </PreChorus>
        <Chorus>
          <p>
            And we'll never be <Ch>B</Ch>royals - It don't run in our blood
          </p>
          <p>
            That kind of <Ch>A</Ch>lux just ain't for us
          </p>
          <p>
            We crave a <Ch>E</Ch>different kind of buzz
          </p>
          <p>
            Let me be your <Ch>B</Ch>ruler - you can call me Queen Bee
          </p>
          <p>
            And baby I'll <Ch>A</Ch>rule, I'll rule, I'll rule, I'll rule
          </p>
          <p>
            <Ch>E</Ch>Let me live that fantasy<Ch>B</Ch>
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>B</Ch>Oooohhh Oooohhh Oooo Ooooo
          </p>
          <p>
            We're <Ch>A</Ch>bigger than we ever dreamed
          </p>
          <p>
            And I'm in <Ch>E</Ch>love with being queen
          </p>
          <p>
            <Ch>B</Ch>Oooohhh Oooohhh Oooo Ooooo
          </p>
          <p>
            <Ch>A</Ch>Life's a game without a care
          </p>
          <p>
            We aren't caught <Ch>E</Ch>up in your love affair
          </p>
        </Bridge>
        <Chorus>
          <p>
            And we'll never be <Ch>B</Ch>royals - It don't run in our blood
          </p>
          <p>
            That kind of <Ch>A</Ch>lux just ain't for us
          </p>
          <p>
            We crave a <Ch>E</Ch>different kind of buzz
          </p>
          <p>
            Let me be your <Ch>B</Ch>ruler - you can call me Queen Bee
          </p>
          <p>
            And baby I'll <Ch>A</Ch>rule, I'll rule, I'll rule, I'll rule
          </p>
          <p>
            <Ch>E</Ch>Let me live that fantasy<Ch>B</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
